import * as React from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { container, subtitle, title1 } from "./Forgotpassword.module.scss";

export const ForgotContainer = (props: any) => {
    return (<div className={container}>{props.children}</div>);
};
export const Subtitle = (props: any) => {
    return (<div id={props.id} className={subtitle}>{props.children}</div>);
};
export const Title1 = (props: any) => {
    return (<h1 id={props.id} className={title1}>{props.children}</h1>);
};

export const ErrorAlert = (props: any) => {
    const { errorMessage, showActivationBtn, handleResendActivationEmail, isResendingEmail } = props;
    return (<Alert id="error-message" variant={`danger`}>
        {errorMessage}
        {
            showActivationBtn &&
            <Button className="ms-2" onClick={handleResendActivationEmail} disabled={isResendingEmail}>
                {isResendingEmail ? `Please wait...` : `Activate Account`}
            </Button>
        }
    </Alert>);
};
export const ErrorInfo = () => {
    return (
        <Alert variant={`danger`}>
            Our team needs to take a closer look at your account. Please visit{" "}
            <Alert.Link href={window.location.origin + "/contact"}>
                {window.location.origin}/contact
            </Alert.Link>{" "}
            to request activation. Thank you!
        </Alert>
    );
};





