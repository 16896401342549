import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  ForgotContainer,
  Subtitle,
  Title1,
  ErrorAlert,
  ErrorInfo,
} from "../pageHelpers/Forgotpassword/ForgotpasswordHelpers";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import Form from "react-bootstrap/Form";
import { forgotPassword, resendActivationEmail } from "../services/crm";
import SuccessModal from "../components/common/SuccessModal/SuccessModal";
import { IUserInfo } from "../@types/IUserInfo";
import { changePassword, changePasswordCDP } from "../utils/auth0Client";
import { Constants } from "../@types/Constants";
import ErrorMessageAlert from "../components/common/ErrorMessageAlert/ErrorMessageAlert";
import { guestServiceProvider } from "../services/ServiceProvider";

const Forgotpassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageWithLink, setErrorMessageWithLink] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showActivationBtn, setShowActivationBtn] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);
  const [isResendingEmail, setIsResendingEmail] = useState(false);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setErrorMessageWithLink(false);
    setIsSubmitting(true);

    try {
      // let ret;
      // if (process.env.GATSBY_IS_CDP_ENABLED !== "ENABLE") {
      //   ret = await changePassword(email.trim());
      // } else {
      //   ret = await changePasswordCDP(email.trim());
      // }
      const guestService = await guestServiceProvider();
      const response = await guestService.sendResetPasswordEmail(email.trim());
      if(response.createResetPasswordEmail.statusCode === "500"){
        setErrorMessage("We cannot reset your password at this time. Please try again later.");
      }else{
        setSuccessMessage("Your password reset request has been received. Please check your email for a confirmation message.");
        setShowSuccess(true);
      }
      
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Caught error in call to changePassword:", err);
      // const { message: errorMsg } = err;
      // setShowActivationBtn(true);

      const errorMsg =
        "We cannot reset your password at this time. Please try again later.";
      setErrorMessage(errorMsg);
    }
    setIsSubmitting(false);
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
    setEmail("");
  };
  const handleResendActivationEmail = async () => {
    setIsResendingEmail(true);
    const data = {
      firstname: userInfo.firstname,
      lastname: userInfo.lastname,
      membernumber: userInfo.membernumber,
      oktaid: userInfo.oktaid,
      email: userInfo.email,
    };
    const response = await resendActivationEmail(data);
    if (!response || response.error) {
      setErrorMessageWithLink(true);
      setShowActivationBtn(false);
    } else {
      setSuccessMessage(
        "You will receive a confirmation email shortly to activate your profile."
      );
      setShowSuccess(true);
      setErrorMessage("");
    }
    setIsResendingEmail(false);
  };

  return (
    <Layout>
      <ForgotContainer>
        <SEO title={`Forgot Password`} />
        <Container fluid="sm" className="pt-5 mb-5">
          <Row>
            <Col lg={4}>
              <Subtitle>TRAVEL PASS </Subtitle>
              <Title1 id={"forgot-password-title"}>Forgot Password</Title1>
            </Col>
            <Col lg={{ span: 7, offset: 1 }} id="forgot-password-description">
              <p>
                <strong>
                  Please enter the email address associated with your account.
                </strong>
                <br />
                If this is a valid account, you will receive an email with your
                username and a temporary password at the email address stored in
                your profile.
              </p>
              {errorMessage ? (
                <ErrorMessageAlert
                  errorType={Constants.ERRORSTYPE.TRAVELPASS}
                  errorSubType={
                    Constants.ERRORSSUBTYPE.TRAVELPASS.FORGOTPASSWORD
                  }
                  message={errorMessage}
                >
                  {!errorMessageWithLink ? (
                    <ErrorAlert
                      errorMessageWithLink={errorMessageWithLink}
                      errorMessage={errorMessage}
                      showActivationBtn={showActivationBtn}
                      handleResendActivationEmail={handleResendActivationEmail}
                      isResendingEmail={isResendingEmail}
                    />
                  ) : (
                    <ErrorInfo />
                  )}
                </ErrorMessageAlert>
              ) : null}
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label id="email-address">Email Address</Form.Label>
                    <Form.Control
                      type={`email`}
                      name={`email`}
                      placeholder={`Email Address`}
                      value={email}
                      onChange={handleEmailChange}
                      disabled={isSubmitting}
                      aria-label="Email Address"
                      autoComplete="email"
                      id="email-value"
                    />
                  </Form.Group>
                </Row>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={{ minWidth: `170px` }}
                  id="submit-forgot-password"
                >
                  {isSubmitting ? `Please wait...` : `Submit`}
                </Button>
              </Form>
            </Col>
          </Row>
          <SuccessModal show={showSuccess} onHide={handleSuccessClose}>
            <Subtitle className="text-center mb-1" id={"confirmation"}>CONFIRMATION</Subtitle>
            <p id={"success-message"}>{successMessage}</p>
          </SuccessModal>
        </Container>
      </ForgotContainer>
    </Layout>
  );
};

export default Forgotpassword;
